import React, { useEffect, useState } from "react";
import {
    getAvg,
    getAllData,
    getMinDate,
    getMaxDate,
} from "../services/dataServices";
import { DateFilterProps } from "../types/DateFilter";

const DateFilter: React.FC<DateFilterProps> = ({ onDateRange, sectorName }) => {
    const [sector, setSector] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [averages, setAverages] = useState<{ [key: string]: number | null }>(
        {},
    ); // State for fetched averages
    const [loadingParameters, setLoadingParameters] = useState<boolean>(false);
    const [sectors, setSectors] = useState<string[]>([]);
    const [parameters, setParameters] = useState<string[]>([]);
    const [formattedMinDate, setFormattedMinDate] = useState<string>("");
    const [formattedMaxDate, setFormattedMaxDate] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [fetchTimeoutId, setFetchTimeoutId] = useState<NodeJS.Timeout | null>(
        null,
    ); // State for timeout ID

    const formatMonth = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 7); // Format date to YYYY-MM
    };

    const incrementMonth = (dateString: string): string => {
        const date = new Date(dateString);
        date.setMonth(date.getMonth() + 1);
        return date.toISOString().slice(0, 7); // Format to YYYY-MM
    };

    // Automatically fetch averages whenever sector, startDate, endDate, and parameters are ready
    useEffect(() => {
        // Clear the previous timeout if it exists
        if (fetchTimeoutId) {
            clearTimeout(fetchTimeoutId);
        }

        // Set a new timeout to fetch averages after a delay
        const timeoutId = setTimeout(() => {
            if (sector && startDate && endDate && parameters.length > 0) {
                fetchAverages();
            }
        }, 500); // Adjust the delay (in milliseconds) as needed

        // Store the timeout ID to clear it later
        setFetchTimeoutId(timeoutId);

        // Cleanup function to clear the timeout on component unmount or dependency change
        return () => clearTimeout(timeoutId);
    }, [sector, startDate, endDate, parameters]);

    // Fetch sector names and date range from the database when the component first mounts
    useEffect(() => {
        const fetchSectorsAndDateRange = async () => {
            try {
                // Fetch all data and extract unique sectors
                const data = await getAllData();
                const uniqueSectors = [
                    ...new Set(data.map((entry) => entry.sector)),
                ];
                setSectors(uniqueSectors);

                // Fetch min and max dates for the data range
                const fetchedMinDate = await getMinDate();
                const fetchedMaxDate = await getMaxDate();

                if (uniqueSectors.length > 0) {
                    const defaultSector = uniqueSectors[0];
                    setSector(defaultSector); // Set the default sector
                    sectorName(defaultSector); // Send default sector to parent
                }

                if (fetchedMinDate) {
                    const formattedMin = formatMonth(fetchedMinDate);
                    setFormattedMinDate(formattedMin); // Store formatted min date
                    setStartDate(formattedMin); // Default start date
                }

                if (fetchedMaxDate) {
                    const formattedMax = formatMonth(fetchedMaxDate);
                    setFormattedMaxDate(formattedMax); // Store formatted max date
                    setEndDate(formattedMax); // Default end date
                }
            } catch (err) {
                // Do nothing
            }
        };

        fetchSectorsAndDateRange();
    }, []);

    // Fetch available parameters whenever the sector changes
    useEffect(() => {
        const fetchParameters = async () => {
            setParameters([]); // Reset parameters when sector changes

            if (!sector) {
                return;
            } // Exit if no sector is selected

            setLoadingParameters(true); // Set loading state while fetching
            console.log(
                "Printing LoadingParameters to Use Variable (no purpose) = ",
                loadingParameters,
            );
            setError(null);

            try {
                const data = await getAllData(); // Fetch all data entries
                const sectorParameters = [
                    ...new Set(
                        data
                            .filter(
                                (entry) =>
                                    entry.sector === sector &&
                                    entry.unitOfMeasure === "KG/D",
                            )
                            .map((entry) =>
                                decodeURIComponent(entry.parameterName),
                            ),
                    ),
                ];

                setParameters(sectorParameters); // Set unique parameters for the selected sector
            } catch (err) {
                setError("Error fetching parameters data"); // Set error if request fails
            } finally {
                setLoadingParameters(false); // Set loading state to false
            }
        };

        fetchParameters();
    }, [sector]);

    // Function to fetch averages for each parameter in the selected sector and date range
    const fetchAverages = async () => {
        setAverages({}); // Clear previous averages
        console.log(
            "Printing Averages to Use Variable (no purpose) = ",
            averages,
        );
        setError(null); // Clear previous error messages

        try {
            const newAverages: { [key: string]: number | null } = {};

            if (parameters.length === 0) {
                setError("No parameters available for the selected sector");
                return; // Exit if no parameters are available
            }

            for (const parameter of parameters) {
                // Encode parameter to handle special characters in URLs
                const encodedParameter = encodeURIComponent(parameter)
                    .replace(/%2F/g, "%252F")
                    .replace(/%2B/g, "%252B");
                const avg = await getAvg(
                    sector,
                    encodedParameter,
                    startDate,
                    incrementMonth(endDate),
                );
                newAverages[parameter] = avg;
            }

            setAverages(newAverages); // Update state with fetched averages
            onDateRange(newAverages); // Send averages to parent component
            sectorName(sector); // Send sector to parent component
        } catch (err) {
            setError("Error fetching average data"); // Set error if request fails
            console.log(
                "Printing Error to Use Variable (no purpose) = ",
                error,
            );
        }
    };

    return (
        <div className="date-page-filter">
            <h1>Average Contamination Levels for Different Sectors</h1>
            <h2>Filter by Date Range</h2>
            <div className="filter-container">
                <div>
                    <label htmlFor="sector">Sector:</label>
                    <select
                        id="sector"
                        value={sector}
                        onChange={(e) => setSector(e.target.value)}
                        required
                        className="custom-dropdown"
                    >
                        {sectors.map((sec, index) => (
                            <option key={index} value={sec}>
                                {sec}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="startDate">Start Date:</label>
                    <input
                        type="month"
                        id="startDate"
                        value={startDate}
                        min={formattedMinDate}
                        max={formattedMaxDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                        className="custom-date-picker"
                    />
                </div>

                <div>
                    <label htmlFor="endDate">End Date:</label>
                    <input
                        type="month"
                        id="endDate"
                        value={endDate}
                        min={formattedMinDate}
                        max={formattedMaxDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                        className="custom-date-picker"
                    />
                </div>
            </div>
        </div>
    );
};

export default DateFilter;
