import React, { useState } from "react";
import ParameterBarGraph from "./ParameterBarGraph";
import DateFilter from "./DateFilter";

const BarGraphSelector: React.FC = () => {
    const [sector, setSector] = useState<string>(""); // State for sector
    const [averages, setAverages] = useState<{ [key: string]: number | null }>(
        {},
    ); // State for averages

    const handleDateRange = (averages: { [key: string]: number | null }) => {
        setAverages(averages); // Set the averages to pass to the graph
    };

    const handleSectorChange = (newSector: string) => {
        setSector(newSector); // Update sector name
    };

    return (
        <div className="graphing">
            <DateFilter
                onDateRange={handleDateRange}
                sectorName={handleSectorChange}
            />
            {sector && (
                <ParameterBarGraph averages={averages} sectorName={sector} />
            )}
        </div>
    );
};

export default BarGraphSelector;
