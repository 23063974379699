import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from "chart.js";
import { ParameterBarGraphProps } from "../types/ParameterBarGraph";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const ParameterBarGraph: React.FC<ParameterBarGraphProps> = ({
    averages,
    sectorName,
}) => {
    // Function to abbreviate long parameter names
    const abbreviateName = (name: string): string => {
        // Adjust the abbreviation logic as needed
        return name.length > 10 ? name.substring(0, 10) + "..." : name;
    };

    // Calculate total and convert each parameter value to percentage
    const values = Object.values(averages);
    const labels = Object.keys(averages); // Store the full labels

    // Check if values array is not empty to prevent possible null errors
    const total: number =
        values.length > 0
            ? values.reduce((sum: number, value) => sum + (value ?? 0), 0)
            : 0;

    const percentageData =
        total > 0
            ? values.map((value) => (value ? (value / total) * 100 : 0)) // Handle possible null values
            : values.map(() => 0); // Default to 0 if total is 0

    // Pair labels with their percentage values
    const pairedData = labels.map((label, index) => ({
        fullLabel: label, // Full label for tooltip
        abbreviatedLabel: abbreviateName(label), // Abbreviated label for x-axis
        percentage: percentageData[index],
    }));

    // Sort the paired data by percentage in descending order
    const sortedData = pairedData.sort((a, b) => b.percentage - a.percentage);

    // Extract the sorted labels and percentage values
    const sortedLabels = sortedData.map((item) => item.abbreviatedLabel); // Use abbreviated names for x-axis
    const sortedPercentages = sortedData.map((item) => item.percentage); // Use sorted percentages

    const barGraphData = {
        labels: sortedLabels, // Use sorted and abbreviated names for x-axis
        datasets: [
            {
                data: sortedPercentages,
                backgroundColor: "rgba(3, 4, 94, 0.75)",
            },
        ],
    };

    // Chart options with correct typing
    const options: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Averages Contamination Levels for " + sectorName,
                color: "#03045E", // Set title text color
                font: {
                    size: 18,
                    weight: "bold",
                },
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        const index = tooltipItems[0].dataIndex;
                        return sortedData[index].fullLabel;
                    },
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw as number;
                        return `${value.toFixed(2)}%`;
                    },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#03045E", // Set x-axis labels color
                },
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: "Parameters", // Label for x-axis
                    color: "#03045E", // Set x-axis title color
                    font: {
                        size: 14,
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: "KG/D (%)",
                    color: "#03045E", // Set y-axis title color
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    color: "#03045E", // Set y-axis labels color
                    callback: function (value: string | number) {
                        return value + "%";
                    },
                },
            },
        },
        layout: {
            padding: 10,
        },
    };

    return (
        <div className="graph-page">
            <div
                className="graph-bg"
                style={{ backgroundColor: "rgba(202, 240, 248, 0.8)" }}
            >
                {/* Renders the bar graph */}
                <Bar data={barGraphData} options={options} />
            </div>
        </div>
    );
};

export default ParameterBarGraph;
