import React from "react";
import { Line } from "react-chartjs-2";
import { ComponentLineGraphProps } from "../types/ComponentLineGraph";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

// Generate a vibrant color using HSL
const generateVibrantColor = (index: number, total: number) => {
    const hue = (360 * index) / total;
    const saturation = 70;
    const lightness = 35;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const ComponentLineGraph: React.FC<ComponentLineGraphProps> = ({
    data,
    Selcomponent,
}) => {
    const lakeNames = Object.keys(data);
    // Define labels using the x-values (dates) from the first lake, if available
    const labels =
        data[lakeNames[0]]?.map((point) =>
            point.x.toLocaleDateString("en-US"),
        ) || [];

    const lineGraphData = {
        labels: labels,
        datasets: lakeNames.map((lakeName, index) => ({
            label: lakeName,
            data: data[lakeName].map((point) => point.y),
            borderColor: generateVibrantColor(index, lakeNames.length),
            backgroundColor: generateVibrantColor(index, lakeNames.length)
                .replace("hsl", "hsla")
                .replace(")", ", 0.2)"), // Semi-transparent fill
            pointRadius: 4,
            pointBorderWidth: 2,
            pointBackgroundColor: "rgba(202, 240, 248, 0.8)",
            fill: false,
            tension: 0.1,
        })),
    };

    const options: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            title: {
                display: true,
                text:
                    Selcomponent.charAt(0).toUpperCase() +
                    Selcomponent.slice(1) +
                    " Levels Over Time for Multiple Lakes",
                color: "#03045E",
                font: {
                    size: 18,
                    weight: "bold",
                },
            },
            tooltip: {
                callbacks: {
                    title: () => "",
                    label: function (tooltipItem) {
                        const lakeName = tooltipItem.dataset.label;
                        const date = tooltipItem.label;
                        const value = tooltipItem.raw as number;
                        return [
                            `Lake: ${lakeName}`,
                            `Date: ${date}`,
                            `${Selcomponent}: ${value.toFixed(2)} mg/L`,
                        ];
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Date",
                    color: "#03045E", // Set x-axis title color
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    color: "#03045E", // Set x-axis labels color
                    maxRotation: 45,
                    minRotation: 45,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: Selcomponent + " (mg/L)",
                    color: "#03045E", // Set y-axis title color
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    color: "#03045E", // Set y-axis labels color
                },
            },
        },
        layout: {
            padding: 10,
        },
    };

    return (
        <div className="graph-page">
            <div
                className="graph-bg"
                style={{ backgroundColor: "rgba(202, 240, 248, 0.8)" }}
            >
                <Line data={lineGraphData} options={options} />
            </div>
        </div>
    );
};

export default ComponentLineGraph;
