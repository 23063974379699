import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashPage from "./components/SplashPage";
import SectorList from "./components/SectorList";
import InlandLakeTable from "./components/InlandLakeTable";
import BarGraphSelector from "./components/BarGraphSelector";
import LineGraphSelector from "./components/LineGraphSelector";
import NavBar from "./components/NavBar";

// Override console.warn to filter out specific warnings
const originalWarn = console.warn;
console.warn = (message, ...optionalParams) => {
    if (!message.includes("React Router Future Flag Warning")) {
        originalWarn(message, ...optionalParams);
    }
};

const App: React.FC = () => {
    return (
        //shows the splash page after the "let's get started button is clicked"
        <Router>
            <div className="App">
                {/* Routes and Navbar are always visible after SplashPage */}
                <header className="App-header">
                    <NavBar />
                    <Routes>
                        {/* SplashPage will be the homepage */}
                        <Route path="/" element={<SplashPage />} />
                        <Route
                            path="/sectors"
                            element={
                                <div className="main-section">
                                    <h1>
                                        Industrial Wastewater Sectors Overview
                                    </h1>
                                    <p>
                                        This section provides a concise table
                                        showcasing all the sectors contributing
                                        to industrial wastewater. Explore each
                                        sector to better understand their impact
                                        on water systems and wastewater
                                        management.
                                    </p>
                                    <SectorList />
                                </div>
                            }
                        />
                        <Route
                            path="/chemicals"
                            element={
                                <div className="main-section">
                                    <h1>
                                        Inland Lake Data Chemical Components
                                        Overview
                                    </h1>
                                    <p>
                                        This section provides a detailed table
                                        of key chemical components found in
                                        inland lake data. Review each component
                                        to understand its characteristics,
                                        implications for water quality, and
                                        potential environmental impacts. Explore
                                        how these chemical elements contribute
                                        to lake health and influence water
                                        management strategies.
                                    </p>
                                    <InlandLakeTable />
                                </div>
                            }
                        />
                        <Route
                            path="/graph-bar"
                            element={
                                <div className="graph-section">
                                    <BarGraphSelector />
                                </div>
                            }
                        />
                        <Route
                            path="/graph-line"
                            element={
                                <div className="graph-section">
                                    <LineGraphSelector />
                                </div>
                            }
                        />
                    </Routes>
                </header>
            </div>
        </Router>
    );
};

export default App;
