import React, { useState, useEffect } from "react";
import ComponentLineGraph from "./ComponentLineGraph";
import { getAllInlandLakesData } from "../services/InlandlakeServices";
import { InlandlakeData } from "../types/InlandlakeData";

const LineGraphSelector: React.FC = () => {
    const [lakeData, setLakeData] = useState<InlandlakeData[]>([]); // Raw lake data
    const [graphData, setGraphData] = useState<{
        [lakeName: string]: { x: Date; y: number }[];
    }>({});
    const [component, setComponent] = useState<string>("calcium"); // Selected sector
    const [components, setComponents] = useState<string[]>([]); // Selected sector

    // Fetch all Inland Lakes data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const newData = await getAllInlandLakesData();
                console.log("InlandLake Data: ", newData);
                setLakeData(newData);
                setComponents(Object.keys(newData[0]).slice(3));
            } catch (error) {
                console.error("Error fetching inland lake data:", error);
            }
        };

        fetchData();
    }, []);

    // Format data for each lake when lakeData changes
    useEffect(() => {
        if (lakeData.length > 0) {
            const formattedData = lakeData.reduce(
                (acc, entry) => {
                    if (!acc[entry.lakeName]) {
                        acc[entry.lakeName] = [];
                    }

                    // Initialize variable for y-axis value
                    let yValue: number;

                    // Use switch case to determine the value based on the selected component
                    switch (component) {
                        case "calcium":
                            yValue = parseFloat(entry.calcium);
                            break;
                        case "alkalinity":
                            yValue = parseFloat(entry.alkalinity);
                            break;
                        case "chloride":
                            yValue = parseFloat(entry.chloride);
                            break;
                        case "potassium":
                            yValue = parseFloat(entry.potassium);
                            break;
                        case "magnesium":
                            yValue = parseFloat(entry.magnesium);
                            break;
                        case "sodium":
                            yValue = parseFloat(entry.sodium);
                            break;
                        case "silicate":
                            yValue = parseFloat(entry.silicate);
                            break;
                        case "sulfate":
                            yValue = parseFloat(entry.sulfate);
                            break;
                        default:
                            yValue = parseFloat(entry.calcium); // Default to calcium
                            setComponent("calcium");
                    }

                    // Push to the graph data
                    acc[entry.lakeName].push({
                        x: new Date(entry.sDate), // Parse date for x-axis
                        y: yValue, // Parsed value for y-axis
                    });

                    return acc;
                },
                {} as { [lakeName: string]: { x: Date; y: number }[] },
            );

            setGraphData(formattedData);
            console.log(formattedData);
        }
    }, [lakeData, component]);

    return (
        <div className="graphing">
            <h1>Calcium Levels Over Time for Multiple Lakes</h1>
            {/* drop down menu to select component*/}
            <div className="filter-container">
                <label htmlFor="Chemical">Chemical:</label>
                <select
                    id="ChemComponent"
                    value={component}
                    onChange={(e) => setComponent(e.target.value)} // Update sector state on selection
                    required
                    className="custom-dropdown" // Apply custom dropdown styling
                >
                    {components.map((name, index) => (
                        <option key={index} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            <ComponentLineGraph data={graphData} Selcomponent={component} />
        </div>
    );
};

export default LineGraphSelector;
