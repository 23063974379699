import React, { useEffect, useState } from "react";
import { getAllInlandLakesData } from "../services/InlandlakeServices";
import { InlandlakeData } from "../types/InlandlakeData";

const InlandlakeTable: React.FC = () => {
    const [lakeData, setLakeData] = useState<InlandlakeData[]>([]); // Store raw lake data
    const [components, setComponents] = useState<string[]>([]); // Store component names (e.g., "calcium", "chloride")

    // Chemical component descriptions (like your dict for sectors)
    const componentDescriptions: { [key: string]: string } = {
        calcium: "Calcium content in water",
        chloride: "Chloride ion concentration",
        alkalinity: "Alkalinity level",
        potassium: "Potassium ion concentration",
        sodium: "Sodium ion concentration",
        magnesium: "Magnesium concentration",
        silicate: "Silicate concentration",
        sulfate: "Sulfate ion concentration",
    };

    // Fetch inland lake data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const newData = await getAllInlandLakesData();
                setLakeData(newData);
                setComponents(Object.keys(componentDescriptions)); // Set components based on the dictionary
            } catch (error) {
                console.error("Error fetching inland lake data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {lakeData.length > 0 && (
                <table className="table-water-quality">
                    <thead>
                        <tr>
                            <th className="table-header">Chemical Component</th>
                            <th className="table-header">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {components.map((component, index) => (
                            <tr key={index}>
                                <td className="table-cell">{component}</td>
                                <td className="table-cell">
                                    {componentDescriptions[component]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default InlandlakeTable;
