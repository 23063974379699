import { WastewaterData } from "../types/WastewaterData";

//Finds the API
const API_URL = "/api/wastewater";

export const getAllData = async (): Promise<WastewaterData[]> => {
    try {
        // wait for fetch request
        const response = await fetch(`${API_URL}/all`);
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        console.log(response);
        // wait for to json
        const data = await response.json();
        return data;
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
        return [];
    }
};

export const getAvg = async (
    sector: string,
    parameterName: string,
    date1: string,
    date2: string,
): Promise<number> => {
    console.log(
        `Fetching note with sector ${sector} from:`,
        `${API_URL}/average/${sector}/${parameterName}/${date1}/${date2}`,
    ); // Log the full endpoint
    try {
        const response = await fetch(
            `${API_URL}/average/${sector}/${parameterName}/${date1}/${date2}`,
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching note:", error);
        throw error;
    }
};

// Fetch the minimum date using fetch
export const getMinDate = async (): Promise<string> => {
    try {
        const response = await fetch(`${API_URL}/min-date`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.text();
        return data;
    } catch (error) {
        console.error("Error fetching minimum date:", error);
        throw error;
    }
};

// Fetch the maximum date using fetch
export const getMaxDate = async (): Promise<string> => {
    try {
        const response = await fetch(`${API_URL}/max-date`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.text();
        return data;
    } catch (error) {
        console.error("Error fetching maximum date:", error);
        throw error;
    }
};
