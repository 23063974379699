import React, { useState } from "react";
import { Link } from "react-router-dom";

// Override console.warn to filter out specific warnings
const originalWarn = console.warn;
console.warn = (message, ...optionalParams) => {
    if (!message.includes("React Router Future Flag Warning")) {
        originalWarn(message, ...optionalParams);
    }
};

const Navbar = () => {
    const [showGraphsDropdown, setShowGraphsDropdown] = useState(false);
    const [showTablesDropdown, setShowTablesDropdown] = useState(false);

    const toggleGraphsDropdown = () =>
        setShowGraphsDropdown(!showGraphsDropdown);
    const toggleTablesDropdown = () =>
        setShowTablesDropdown(!showTablesDropdown);

    return (
        <nav className="navbar">
            {/* Logo/Title that links to the home page */}
            <div className="favicon">
                <Link to="/" className="nav-logo">
                    WaterWize
                </Link>
            </div>

            {/* Navigation Links */}
            <div className="nav-links">
                {/* Tables Dropdown Toggle */}
                <div
                    className={`nav-item ${showTablesDropdown ? "nav-item-active" : ""}`}
                    onClick={toggleTablesDropdown}
                >
                    <span className="nav-link">Tables</span>
                    {showTablesDropdown && (
                        <span className="dropdown-separator"> | </span>
                    )}
                </div>

                {/* Conditionally Rendered Table Options Inline with Nav Links */}
                {showTablesDropdown && (
                    <>
                        <Link to="/sectors" className="nav-linkage">
                            Sectors
                        </Link>
                        <Link to="/chemicals" className="nav-linkage">
                            Chemicals
                        </Link>
                    </>
                )}

                {/* Graphs Dropdown Toggle */}
                <div
                    className={`nav-item ${showGraphsDropdown ? "nav-item-active" : ""}`}
                    onClick={toggleGraphsDropdown}
                >
                    <span className="nav-link">Graphs</span>
                    {showGraphsDropdown && (
                        <span className="dropdown-separator"> | </span>
                    )}
                </div>

                {/* Conditionally Rendered Graph Options Inline with Nav Links */}
                {showGraphsDropdown && (
                    <>
                        <Link to="/graph-bar" className="nav-linkage">
                            Industrial Wastewater
                        </Link>
                        <Link to="/graph-line" className="nav-linkage">
                            Inland Lakes
                        </Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
