import { InlandlakeData } from "../types/InlandlakeData";

//Finds the API
const API_URL = "/api/inland_lakes";

export const getAllInlandLakesData = async (): Promise<InlandlakeData[]> => {
    try {
        // wait for fetch request
        const response = await fetch(`${API_URL}/all`);
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        console.log(response);
        // wait for to json
        const data = await response.json();

        console.log("[InlandLakeServices.ts] First data item:", data[0]);

        return data;
    } catch (err) {
        console.error(`Error fetching data: ${err}`);
        return [];
    }
};
